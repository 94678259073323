
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


const firebaseConfig = {

    apiKey: "AIzaSyBhclizk98ZiIyvck2o0Bs6pn7qF_M22Wc",
  
    authDomain: "payinstacard-new.firebaseapp.com",
  
    projectId: "payinstacard-new",
  
    storageBucket: "payinstacard-new.appspot.com",
  
    messagingSenderId: "858580304506",
  
    appId: "1:858580304506:web:e3343ace0ce3e5e67271c3",
  
    measurementId: "G-2TTY9FTDCR"
  
  };
  


const app = initializeApp(firebaseConfig);
console.log(app);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export default app;