import React from "react";
import heroBackground from "../../assets/aboutUsHeroBackground.svg";
import heroPhone from "../../assets/heroPhone.svg";
import finance from "../../assets/FINANCE.svg";
import iPhone from "../../assets/iPhoneX.svg";
import { Icon } from "@iconify/react";
import saiKrishnaPic from "../../assets/saiKrishnaPic.svg";
import nageshPic from "../../assets/nageshPic.svg";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="font-lato">
     <Helmet>
                <meta charSet="utf-8" />
                <title>About us</title>
                <link rel="canonical" href="https://payinstacard.com/about-us" />
            </Helmet>
      <div
        className="lg:mt-[2rem] bg-no-repeat py-[4rem] xl:py-[6rem] max-sm:px-[1rem]  "
        style={{ backgroundImage: `url(${heroBackground})`, backgroundSize:"100% auto" }}
      >
        <div className="flex flex-col max-lg:items-center gap-y-[4rem] lg:flex-row max-w-[1280px] mx-auto w-full max-xl:justify-evenly xl:gap-[12rem]">
         
          <div className="max-w-[565px] xl:max-w-[690px] flex flex-col gap-[2rem]">
            <h1 className="text-[1.5rem] xs:max-xl:text-[2rem] xl:text-[3rem] font-[800] text-[#18191F] ">
              Secure, Instant and Simple <br className="hidden lg:flex" /> with Payinstacard.
            </h1>
            <p className="text-[0.8rem] xl:text-[1rem] font-[500] leading-9 text-[#18191F] ">
              Welcome to Payinstacard Private Limited, the ultimate destination
              for effortless bill payments and rewarding cashback experiences.
              Founded in 2022, our mission is to simplify your bill payment
              process and revolutionize how you interact with your bills. With
              our user-friendly platform, settling bills becomes a breeze,
              requiring just a few taps on your mobile device. But that’s not
              all – we take it a step further. Our innovative approach offers
              real money as cashback for every bill you pay, providing tangible
              rewards that enhance your financial well-being. Say goodbye to
              mundane bill payments and embrace a truly rewarding experience
              with Payinstacard.{" "}
            </p>
          </div>
          <div className="z-10 shrink-0 relative">
           

            <img src={heroPhone} alt="" className="w-[18rem] xl:w-[22rem]  h-auto" />
           
          </div>
            {/* <img
            src={finance}
            alt=""
            className="absolute top-[24rem] lg:top-[32rem] right-[1rem] w-[30rem] lg:max-xl:w-[25rem] xl:w-[45rem] h-auto"
          /> */}
        </div>
      </div>
      <div className="flex flex-col gap-y-[4rem] lg:flex-row w-full justify-between items-center max-w-[1320px] mx-auto pt-[4rem] sm:pt-[5rem] max-xl:px-[1rem]">
        <div className="px-[4rem] pt-[3rem] bg-[#01016A] rounded-[20px]">
          <img src={iPhone} alt="" className="w-[22rem] xl:w-[28rem] h-auto" />
        </div>
        <div className="max-w-[690px] flex flex-col gap-[2rem] ">
          <h1 className="text-[1.5rem] xs:max-xl:text-[2rem] xl:text-[2.6rem] font-[800] text-[#18191F] leading-11 ">
            Say Hello To Cashback <br className="hidden lg:flex" /> On Bill Payments​
          </h1>

          <ul
            className="text-[1rem] font-normal leading-10 "
           
          >
            <li className="flex flex-row  text-[0.9rem] xl:text-[1.2rem] text-[#18191F]"><Icon icon="bi:dot"  className="mr-[0.5rem] text-[2rem] mt-[0.2rem]"/>
              Payinstacard offers a simple and seamless solution for all bill
              payments.​
            </li>
            <li className="flex flex-row  text-[0.9rem] xl:text-[1.2rem] text-[#18191F]">
            <Icon icon="bi:dot"  className="mr-[0.5rem] text-[2rem] mt-[0.2rem]"/>
              Pay all bills in one place, including rent
              and more.
            </li>
            <li className="flex flex-row  text-[0.9rem] xl:text-[1.2rem] text-[#18191F]"> <Icon icon="bi:dot" className="mr-[0.5rem] text-[2rem] mt-[0.2rem]" />Earn cashback on every transaction with our platform.​</li>
          </ul>
          <p className="flex flex-row gap-[1rem] text-[#00006B] items-center  cursor-pointer">
            <span className="font-[600] text-[1.4rem] text-[#00006B]">
              Get Started
            </span>
            <Icon icon="cil:arrow-right" className="text-[1.8rem]" />
          </p>
        </div>
      </div>
      {/* <div className="text-center flex flex-col items-center gap-[4rem] pt-[6rem] sm:pt-[8rem] pb-[4rem]">
        <h1 className="text-[1.5rem] xs:max-lg:text-[2rem] lg:max-xl:text-[2.5rem] xl:text-[3rem] font-[800] text-[#18191F] ">Our Team</h1>
        <div className="flex flex-row gap-[1rem] xs:gap-[2rem]">
          <div
            className="flex flex-col p-[0.5rem] sm:p-[1rem] bg-white"
            style={{ boxShadow: "0px 28px 40px rgba(0, 0, 0, 0.1)" }}
          >
            <div>
              <img src={saiKrishnaPic} alt="" className="w-[9rem] xs:max-sm:w-[10rem] sm:max-lg:w-[12rem] lg:w-[17rem] h-auto" />
            </div>
            <div className="text-center py-[1.5rem] sm:py-[2rem]">
              <p className="text-black text-[0.9rem] sm:max-lg:text-[1rem] lg:text-[1.5rem] font-[600]">
                Sai Krishna Musunuru
              </p>
              <p className="text-black/[0.6] text-[0.7rem] sm:text-[0.9rem] font-[400]">
                Co-founder & CEO
              </p>
            </div>
          </div>
          <div
            className="flex flex-col p-[0.5rem] sm:p-[1rem] bg-white"
            style={{ boxShadow: "0px 28px 40px rgba(0, 0, 0, 0.1)" }}
          >
            <div>
              <img src={nageshPic} alt="" className="w-[9rem] xs:max-sm:w-[10rem] sm:max-lg:w-[12rem] lg:w-[17rem] h-auto" />
            </div>
            <div className="text-center py-[1.5rem] sm:py-[2rem]">
              <p className="text-black text-[0.9rem] sm:max-lg:text-[1rem] lg:text-[1.5rem] font-[600]">
                Sri Nagesh Kotipalli
              </p>
              <p className="text-black/[0.6] text-[0.7rem] sm:text-[0.9rem] font-[400]">
                Co-founder
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutUs;
