
import axios from "axios";
import { APIUrls } from "../../baseUrl/BaseUrl";
import _ from "lodash";

const LogVisitors = async (token)=>{ 


   // Check if the visitor has already been logged
   const visitorLogged = sessionStorage.getItem('visitorLogged');


    // Get browser CodeName
const browserCodeName = navigator.appCodeName;

// Get browser Name
const browserName = navigator.appName;

// Get browser Version
const browserVersion = navigator.appVersion;

// Check if cookies are enabled
const cookiesEnabled = navigator.cookieEnabled;

// Get platform
const platform = navigator.platform;

// Get user-agent header
const userAgent = navigator.userAgent;
// Additional properties you can include
const language = navigator.language;
const online = navigator.onLine;
const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || 'unspecified';
const javaEnabled = navigator.javaEnabled();

// Create the client information object
const info = {
browserCodeName,
browserName,
browserVersion,
cookiesEnabled,
platform,
userAgent,
language,
online,
doNotTrack,
javaEnabled,
};


if(_.isEmpty(visitorLogged) || visitorLogged == false  ){

  await axios.post(APIUrls.log_visitors, {device_info: info},
    {
     headers: {
       Authorization: `Bearer ${token}`,
       "Content-Type": "application/json",
     },
    }
    ).then(async data =>{
     sessionStorage.setItem('visitorLogged', 'true');
    }).catch(err =>console.log(err));
}


return true
}


export default LogVisitors;