import React, { useState } from "react";
import Modal from "./Modal";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { toast, ToastContainer } from "react-toastify";
import aadhaarAvatar from '../../assets/aadhaarAvatar.svg';
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../stores/AuthContext";
import _ from 'lodash';
import { payActions } from "../../stores/IndexRedux";
import Loader from "../loader/Loader";

const VerifyPanCardModal = ({ isOpenModal, setIsOpenModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const[isFirstOpen,setIsFirstOpen] = useState(true);
  const[isSecondOpen,setIsSecondOpen] = useState(false);
  const [_PANDetails, setPANDetails] = useState();
  const {getAccessToken} = useAuth()
  const {KYCRefetch, KYCRefetchGlobal} = useSelector((state)=>state.pay)
  const dispatch = useDispatch();
  const handleGoBack = ()=>{
    if(isSecondOpen){
      setIsSecondOpen(false);
      setIsFirstOpen(true);
    }
  }

  const fetchDetailsFunc = async ()=>{
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const kycResponse = await fetch(APIUrls.fetchKYCDetails, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });

      const kycResponseJson = await kycResponse.json();
      console.log(kycResponseJson);
      if (_.isEmpty(kycResponseJson)) {
        setIsLoading(false)
        return toast.error("Something went wrong!!");
      }

      if (kycResponseJson?.code === 200) {
        console.log(kycResponseJson.response.KYCDetails);
        if (kycResponseJson.response.KYCDetails._PANVerified) {
          dispatch(payActions.updatePANVerification({ panVerified: true }));
        }
        setPANDetails(kycResponseJson.response.KYCDetails.Pan_details);
        setIsLoading(false)
        return
      }

      if (kycResponseJson?.code !== 200) {
        const message = kycResponseJson.message;
        setIsLoading(false)
       return toast.error(message);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      toast.error("Something went wrong.");
    }
  }

  const onSubmit = async (data, event)=>{
      event.preventDefault();
      setIsLoading(true);
      try {
        const {pancardNumber} = data;
        console.log(pancardNumber)
        const token = await getAccessToken();
        const response= await fetch(APIUrls.verifyPAN,{
          method:"POST",
          body: JSON.stringify({pan:pancardNumber}),
          headers:{
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json"
          }
        });
        const responseJson = await response.json();
        if (_.isEmpty(responseJson)) {
          setIsLoading(false);
          return toast.error("Something went wrong.");
        } else if (responseJson?.code === 200) {
    
          setIsFirstOpen(false);
          setIsSecondOpen(true);
          setIsLoading(false);
          toast.success(responseJson.message);
          fetchDetailsFunc();
          return window.location.reload(false);
        } else if (responseJson.code !== 200) {
          setIsLoading(false);
          return toast.error(responseJson.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
        
  }

  console.log(_PANDetails)

 
  return (
    <>
    <Modal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}>
      <div className="min-w-[22rem] xs:min-w-[28rem] sm:min-w-[35rem] min-h-[20rem] max-h-[85vh] landscape:py-[2rem] py-[4rem] px-[1.5rem] xs:px-[2rem] flex flex-col gap-[2rem] relative">
        <Icon
          icon="radix-icons:cross-circled"
          className="absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={()=>{setIsOpenModal(false)}}
        />

        <div className="pb-[1rem] border-b border-black/[0.2] flex flex-row gap-[1rem] items-center">
        {isSecondOpen  ? <Icon icon="octicon:arrow-left-16" className="text-[1.5rem] cursor-pointer" onClick={()=>{handleGoBack()}} /> : null}

          <p className="text-[#2F2F2F] font-bold  text-[1rem] xs:text-[1.2rem] flex">
       {isFirstOpen ? "Verify Your Identity" :"Pancard Details"}
          </p>
        </div>
        <div className="flex flex-row justify-center w-full">
          {isFirstOpen && <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-[2rem] w-[16rem] xs:w-[18rem]  "
          >
            <div className=" flex flex-col gap-[1rem]">
              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="pancardNumber"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Enter Pancard Number
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("pancardNumber", {
                    required: "This field is required.",
                   pattern: {
                      value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message: "Invalid pan.",
                    },
                  })}
                />

                <div className="text-[0.7rem] xs:text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>Enter your 10 digit unique aadhar number to verify your identity</span>
                </div>

                {/* {errors?.aadhaarNumber && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.otp?.message}</span>
                  </div>
                )} */}
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading || !isValid}
              className={`${
                !isValid || isLoading ? "bg-[#00006B]/[0.5]" : "bg-[#00006B]"
              } text-center w-full rounded-[30px] text-white py-[0.5rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]`}
            >
              {isLoading && (
                <CgSpinner size={20} className="mt-1 animate-spin" />
              )}
              Verify
            </button>
          </form>}
          
        {isSecondOpen &&  <div className="flex flex-col w-full items-center  gap-[5rem]">

        <div className="grid grid-cols-3 gap-y-[1rem] w-full">
            <div className="flex flex-col gap-[0.5rem]">
              <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">Pancard Number</h3>
              <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                {_PANDetails?.pan || "-"}
              </h2>
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">Full Name</h3>
              <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
              {_PANDetails?.registered_name || "-"}
                
              </h2>
            </div>
            <div className="flex flex-col gap-[0.5rem] justify-self-center">
              <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">Date of Birth</h3>
              <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">-</h2>
            </div>
          
          </div>
          <button className="bg-[#00006B] text-white font-bold text-[0.8rem] xs:text-[1rem] rounded-[30px] py-[0.5rem] w-[15rem]" onClick={()=>{setIsOpenModal(false); KYCRefetch();  KYCRefetchGlobal()}}>Done</button>
          </div>}
        </div>
      </div>
    </Modal>
    {isLoading && isSecondOpen && <Loader/>}
    <ToastContainer/>
    </>
  );
};

export default VerifyPanCardModal;
