import React, { useState , useEffect} from "react";
import Modal from "./Modal";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { toast, ToastContainer } from "react-toastify";
import aadhaarAvatar from "../../assets/aadhaarAvatar.svg";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { payActions } from "../../stores/IndexRedux";
import _ from 'lodash';
import Loader from '../../components/loader/Loader';

const VerifyAadhaarModal = ({ isOpenModal, setIsOpenModal  }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm();
  const { getAccessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [isThirdOpen, setIsThirdOpen] = useState(false);
  const [aadhaarRefId, setAadhaarRefId] = useState();
  const [aadhaarDetails, setAadhaarDetails] = useState();
  const [aadhaarPhoto, setAadhaarPhoto] = useState();
  const [aadhaarNum, setAadhaarNum] = useState();
  const [timer, setTimer] = useState(60);
 

  console.log(isFirstOpen, isSecondOpen, isThirdOpen);
  const { aadhaarVerified, KYCRefetch, KYCRefetchGlobal} = useSelector((state)=>state.pay);
 const dispatch= useDispatch()
  const handleGoBack = () => {
    if (isThirdOpen) {
      setIsThirdOpen(false);
      setIsSecondOpen(true);
    } else if (isSecondOpen) {
      setIsSecondOpen(false);
      setIsThirdOpen(false);
      setIsFirstOpen(true);
    }
  };

  const getOTPHandler = async (data, event) => {
    event.preventDefault();
    const { aadhaarNumber } = data;

    
    setAadhaarNum(aadhaarNumber)
    console.log(aadhaarNumber);
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      console.log(token);
      const response = await fetch(APIUrls.generateAadhaarOTP, {
        method: "POST",
        body: JSON.stringify({ aadhaar_number: aadhaarNumber }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      if (_.isEmpty(responseJson)) {
        setIsLoading(false)
        return toast.error("Something went wrong.");
      } else if (responseJson?.code === 200) {
        setAadhaarRefId(responseJson.response.ref_id);
        setIsFirstOpen(false);
        setIsThirdOpen(false);
        setIsSecondOpen(true);
        setIsLoading(false)
        return toast.success(responseJson.message);
      } else if (responseJson.code !== 200) {
        setIsLoading(false)
        return toast.error(responseJson.message);
      }
      console.log(responseJson);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
       toast.error("Something went wrong.");
    }
  };

  const fetchDetailsFunc = async ()=>{
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const kycResponse = await fetch(APIUrls.fetchKYCDetails, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });

      const kycResponseJson = await kycResponse.json();
      console.log(kycResponseJson);
      if (_.isEmpty(kycResponseJson)) {
        setIsLoading(false);
        return toast.error("Something went wrong!!");
      }

      if (kycResponseJson?.code === 200) {
        console.log(kycResponseJson.response.KYCDetails);
        if (kycResponseJson.response.KYCDetails.aadhaarVerified) {
          dispatch(payActions.updateAadhaarVerification({ aadhaarVerified: true }));
        }
        setAadhaarDetails(kycResponseJson.response.KYCDetails.Aadhar_Details);
        setAadhaarPhoto(
          kycResponseJson.response.KYCDetails.Aadhar_Details?.photo_link
        );
        setIsLoading(false);



        return
      }

      if (kycResponseJson?.code !== 200) {
        const message = kycResponseJson.message;
        setIsLoading(false);
       return toast.error(message);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      toast.error("Something went wrong.");
    }
  }

  const submitOTP = async (data, event) => {
   
    const reff_id = localStorage.getItem('referalID') || ""

    console.log('refff',reff_id)

  
   
    try {
      setIsLoading(true);
      const { aadhaarOTP } = data;
      const token = await getAccessToken();
      const response = await fetch(APIUrls.submitAadhaarOTP, {
        method: "POST",
        body: JSON.stringify({ otp: aadhaarOTP, ref_id: aadhaarRefId, aadhaar_number: aadhaarNum , referal_id:reff_id}),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      const responseJson = await response.json();
      console.log(responseJson);
      if (_.isEmpty(responseJson)) {
        setIsLoading(false);
        return toast.error("Something went wrong.");
      } else if (responseJson?.code === 201) {
        toast.success(responseJson.message);
        setIsFirstOpen(false);
        setIsSecondOpen(false);
        setIsThirdOpen(true);
        setIsLoading(false);
        fetchDetailsFunc()
        return window.location.reload(false);
      } else if (responseJson.code !== 201) {
        setIsLoading(false);
        return toast.error(responseJson.message);
      }
      console.log(responseJson);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    let intervalId;

    if (isSecondOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isSecondOpen, timer]);

  const handleResendOTP = async ()=>{
   
    try {
    
      const token = await getAccessToken();
      console.log(token);
      const response = await fetch(APIUrls.generateAadhaarOTP, {
        method: "POST",
        body: JSON.stringify({ aadhaar_number: aadhaarNum }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      if (_.isEmpty(responseJson)) {
        
        toast.error("Something went wrong.");
      } else if (responseJson?.code === 200) {
        setAadhaarRefId(responseJson.response.ref_id);
        
        toast.success(responseJson.message);
      } else if (responseJson.code !== 200) {
        
        toast.error(responseJson.message);
      }
      console.log(responseJson);
    } catch (error) {
      
      console.log(error);
       toast.error("Something went wrong.");
    }
    setTimer(60);
    
  }
  
  return (
    <>
    <Modal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}>
      <div className="min-w-[22rem] xs:min-w-[28rem] sm:min-w-[35rem] min-h-[20rem] max-h-[85vh] landscape:py-[2rem] py-[4rem] px-[1.5rem] xs:px-[2rem] flex flex-col gap-[2rem] relative">
        <Icon
          icon="radix-icons:cross-circled"
          className="absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={() => {
            setIsOpenModal(false);
          }}
        />

        <div className="pb-[1rem] border-b border-black/[0.2] flex flex-row gap-[1rem] items-center">
          {isSecondOpen || isThirdOpen ? (
            <Icon
              icon="octicon:arrow-left-16"
              className="text-[1.5rem] cursor-pointer"
              onClick={() => {
                handleGoBack();
              }}
            />
          ) : null}

          <p className="text-[#2F2F2F] font-bold text-[1rem] xs:text-[1.2rem] flex">
            {isFirstOpen
              ? "Verify Your Identity"
              : isSecondOpen
              ? "Verify Your Phone Number"
              : "Aadhar Details"}
          </p>
        </div>
        <div className="flex flex-row justify-center w-full">
          {isFirstOpen && (
            <form
              action=""
              onSubmit={handleSubmit(getOTPHandler)}
              className="flex flex-col gap-[2rem] w-[16rem] xs:w-[18rem]  "
            >
              <div className=" flex flex-col gap-[1rem]">
                <div className="flex flex-col gap-[0.5rem]">
                  <label
                    htmlFor="aadhaarNumber"
                    className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                  >
                    Enter Aadhaar Number
                  </label>
                  <input
                    type="text"
                    className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                    {...register("aadhaarNumber", {
                      required: "This field is required.",
                      pattern: {
                        value: /^\d{12}$/,
                        message: "Invalid aadhaar number",
                      },
                    })}
                  />

                  <div className="text-[0.7rem] xs:text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[2rem]"
                    />
                    <span>
                      Enter your 12 digit unique aadhar number to verify your
                      identity
                    </span>
                  </div>

                  {/* {errors?.aadhaarNumber && (
                  <div className=" xs:text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.otp?.message}|| jjjsjsjjs</span>
                  </div>
                )} */}
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading || !isValid}
                className={`${
                  !isValid || isLoading ? "bg-[#00006B]/[0.5]" : "bg-[#00006B]"
                } text-center w-full rounded-[30px] text-white py-[0.5rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]`}
              >
                {isLoading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                Send OTP
              </button>
            </form>
          )}
          {isSecondOpen && (
            <form
              action=""
              onSubmit={handleSubmit(submitOTP)}
              className="flex flex-col gap-[2rem] w-[20rem]  "
            >
              <div className=" flex flex-col gap-[1rem]">
                <div className="flex flex-col gap-[0.5rem]">
                  <label
                    htmlFor="aadhaarNumber"
                    className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                  >
                    Enter the Code we sent to Aadhar Registered Mobile Number{" "}
               
                  </label>
                  <input
                    type="text"
                    className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                    {...register("aadhaarOTP", {
                      required: "This field is required.",
                      pattern: {
                        value: /^\d{6}$/,
                        message: "Invalid OTP",
                      },
                    })}
                  />
                 
                  <div className="flex justify-end">
                   {timer> 0 ? <p className='text-[#00006B]/[0.6] text-[0.8rem] xs:text-[1rem] font-bold'>Resend OTP in {timer} sec </p> : 
                    <button type="button" className='text-[#00006B] text-[0.8rem] xs:text-[1rem] font-bold' onClick={()=>{handleResendOTP()}}>Resend OTP</button>}
                </div>

                  {errors?.aadhaarOTP && (
                    <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                      <Icon
                        icon="material-symbols:info-rounded"
                        className="text-[1.5rem]"
                      />
                      <span>{errors?.aadhaarOTP?.message}</span>
                    </div>
                  )}
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading || !isValid}
                className={`${
                  !isValid || isLoading ? "bg-[#00006B]/[0.5]" : "bg-[#00006B]"
                } text-center w-full rounded-[30px] text-white py-[0.5rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]`}
              >
                {isLoading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                Submit
              </button>
            </form>
          )}
          {isThirdOpen && (
            <>
          
            <div className="flex flex-col w-full items-center gap-[1rem]">
              <div className="grid grid-cols-3 gap-y-[1rem] w-full">
                <div className="flex flex-col gap-[0.5rem]">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    Photo
                  </h3>
                  <div>
                    <img
                      src={
                        aadhaarPhoto
                          ? `data:image/jpeg;base64,${aadhaarPhoto}`
                          : aadhaarAvatar
                      }
                      alt=""
                      className="w-[2rem] h-[2rem] rounded-md"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    UID
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                  {aadhaarDetails?.aadhaar_number || "-"}
                  </h2>
                </div>
                <div className="flex flex-col gap-[0.5rem] ">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    Full Name
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                     {aadhaarDetails?.name || "-"}
                  </h2>
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    S/O
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                  {aadhaarDetails?.care_of || "-"}
                  </h2>
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    Gender
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                  {aadhaarDetails?.gender || "-"}
                  </h2>
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    Date of Birth
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                  {aadhaarDetails?.dob || "-"}
                  </h2>
                </div>
                <div className="flex flex-col gap-[0.5rem] col-span-3">
                  <h3 className="text-[0.55rem] sm:text-[0.6rem] md:text-[0.8rem] text-[#8A8A8A] font-bold">
                    Address
                  </h3>
                  <h2 className="text-[0.6rem] sm:text-[0.7rem] md:text-[1rem] text-[#2F2F2F] font-bold">
                  {aadhaarDetails?.address || "-"}
                  </h2>
                </div>
              </div>
              <button className="bg-[#00006B] text-white font-bold text-[0.8rem] xs:text-[1rem] rounded-[30px] py-[0.5rem] w-[15rem]" onClick={()=>{setIsOpenModal(false); KYCRefetch();  KYCRefetchGlobal();}}>
                Done
              </button>
            </div>
            </>
          )}
        </div>
      </div>
    </Modal>
      <ToastContainer/>
      {isLoading && isThirdOpen && <Loader/>}
      </>
  );
};

export default VerifyAadhaarModal;
