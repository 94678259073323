import React, { useState, useEffect } from "react";
import GrayColoredCardWrapper from "../cards/GrayColoredCardWrapper";
import { Icon } from "@iconify/react";
import aadhaarAvatar from "../../assets/aadhaarAvatar.svg";
import VerifyAadhaarModal from "../modals/VerifyAadhaarModal";
import VerifyPanCardModal from "../modals/VerifyPanCardModal";
import Pen from "../../assets/Pen.svg";
import { IconButton } from "@material-tailwind/react";
import AddBeneficiaryModal from "../modals/AddBeneficiaryModal";
import _ from "lodash";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Loaders from "../loader/Loader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { payActions } from "../../stores/IndexRedux";
import { Tooltip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import copyIcon from "../../assets/copyIcon.png";
import shareToFriend from "../../assets/sharetofriend.svg";
import register from "../../assets/register.svg";
import reward from "../../assets/reward.svg";
import { Link } from "react-router-dom";
import moment from "moment";

const ReferralProgram = () => {
  const referal_data = useSelector((state) => state?.userData);

  const state_referal_code = useSelector(
    (state) => state?.userData.referal_code
  );
  const state_referal_url = useSelector((state) => state?.userData.referal_url);
  const total_earnings = useSelector((state) => state?.userData.earning_total);

  const [referal_code, setReferal_code] = useState("");
  const [referal_url, setReferal_url] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setReferal_code(state_referal_code);
    setReferal_url(state_referal_url);
  }, [state_referal_code, state_referal_url]);

  // TOOL TIP

  const copytoClip = () => {
    return toast.success("Copied successfully !");
  };

  return (
    <div className="m-2 xs:m-6">
      <div className="">
        <h2 className=" text-xl xs:text-[28px] font-bold">Refer & Earn</h2>
        <h4 className="text-xs  xs:text-xl mt-6 mb-4 font-semibold">
          Referral Code
        </h4>
        {/* <div className="mt-2 grid grid-cols-2 gap-8 items-center"> */}

        <div className="flex flex-col xs:items-center xs:flex-row xs:justify-between xs:gap-2 ">
          <div className="flex justify-between items-center border-dashed border border-[#00006B] px-5 py-1 rounded-lg mb-8 xs:w-1/2 xs:mb-0">
            <p className="font-extrabold text-xs">{referal_code}</p>
            <button
              className="bg-[#00006B] w-24 xs:w-32 h-8 xs:h-11 text-white rounded-[10px] text-[.75rem] xs:text-sm"
              onClick={() => navigator.clipboard.writeText(referal_url)}
            >
              <span>
                <img
                  src={copyIcon}
                  alt="copyIcon"
                  className="inline-block mr-[5px] xs:mr-[10px] "
                />
              </span>
              Copy
            </button>
          </div>

          <div className="flex justify-around xs:w-1/2">
            <Link to={`https://wa.me/?text=${referal_url}`} target="_blank">
              <Icon
                className="inline"
                icon="ic:twotone-whatsapp"
                color="#00006b"
                width="32"
                height="32"
              />
            </Link>
            <Link
              to={`https://www.facebook.com/sharer/sharer.php?u=${referal_url}`}
              target="_blank"
            >
              <Icon
                className="inline"
                icon="bxl:facebook"
                color="#00006b"
                width="32"
                height="32"
              />
            </Link>
            <Link to="#" target="_blank">
              <Icon
                className="inline"
                icon="ic:baseline-telegram"
                color="#00006b"
                width="32"
                height="32"
              />
            </Link>
            <Link
              to={`https://www.linkedin.com/sharing/share-offsite/?url=${referal_url}`}
              target="_blank"
            >
              <Icon
                icon="mdi:linkedin"
                color="#00006b"
                width="32"
                height="32"
              />
            </Link>
            <Link to="" target="_blank">
              <Icon
                className="inline"
                icon="gridicons:ellipsis"
                color="#00006b"
                width="32"
                height="32"
              />
            </Link>
          </div>
        </div>

        <div className="mt-12 xs:flex xs:justify-center">
          <div className="flex gap-6 mb-6 xs:flex-col xs:text-center xs:gap-2">
            <div>
              <img
                src={shareToFriend}
                alt="copyIcon"
                className="mx-auto max-w-none"
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-1 text-base xs:my-2">
                Share to Friend
              </h3>

              <p className="text-[#8A8A8A] text-sm xs:text-base xs:w-3/4 xs:mx-auto">
                Invite your friend using Referral link & get register on
                Payinstacard App.
              </p>
            </div>
          </div>
          <div className="flex gap-6 mb-6 xs:flex-col xs:text-center xs:gap-2">
            <div>
              <img
                src={register}
                alt="copyIcon"
                className="mx-auto max-w-none"
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-1 text-base xs:my-2">
                Register
              </h3>

              <p className="text-[#8A8A8A] text-sm xs:text-base xs:w-3/4 xs:mx-auto">
                The referred person need to complete KYC & make transaction.
              </p>
            </div>
          </div>
          <div className="flex gap-6 mb-6 xs:flex-col xs:text-center xs:gap-2">
            <div>
              <img src={reward} alt="copyIcon" className="mx-auto max-w-none" />
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-1 text-base xs:my-2">
                Reward
              </h3>

              <p className="text-[#8A8A8A] text-sm xs:text-base xs:w-3/4 xs:mx-auto">
                For each transaction by your referred person you will get 0.3%
                of the total as Rewards.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 xs:mt-12 xs:mx-12">
    <h2 className="text-xl xs:text-2xl font-semibold">
        Payinstacard Refer & Earn Program - Terms and Conditions
    </h2>
    <ol className=" mx-6 mt-4 text-[#393939] text-sm leading-2 xs:text-base xs:leading-10">
        <li className="mb-2">
            <strong>Eligibility:</strong>
            <ol className="list-decimal ml-6 mt-2">
                <li className="mb-2">The Payinstacard Refer & Earn Program (the "Program") is open to all Payinstacard users who have an active Payinstacard account.</li>
                <li className="mb-2">Users must be in compliance with Payinstacard's terms of service and policies to participate in the Program.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Referral Process:</strong>
            <ol className="list-decimal ml-6 mt-2">
                <li className="mb-2">To participate, users can generate a unique referral link from their Payinstacard account.</li>
                <li className="mb-2">Users are encouraged to share this referral link with friends, family, or acquaintances who are not currently Payinstacard users.</li>
                <li className="mb-2">Referral rewards are earned when a referred individual successfully signs up for Payinstacard and makes a rent payment using a credit card.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Reward Calculation:</strong>
            <ol className="list-decimal ml-6 mt-2">
                <li className="mb-2">Participants will earn a reward equal to 0.3% of the transaction amount for every rent payment made by their referred individuals.</li>
                <li className="mb-2">Rewards will be credited to the participant's Payinstacard account in real-time upon successful rent payment by the referred individual.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Reward Limits:</strong>
            <ol className="list-decimal ml-6 mt-2">
                <li className="mb-2">There is no limit on the number of referrals a participant can make.</li>
                <li className="mb-2">The Program does not impose a cap on the total rewards a participant can earn.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Valid Referrals:</strong>
            <ol className=" ml-6 mt-2">
                <li className="mb-2"> A referral is considered valid when the referred individual:</li>
                <ul className="list-disc ml-6 mt-2">
                    <li className="mb-2">Signs up for a Payinstacard account using the referral link.</li>
                    <li className="mb-2">Successfully makes a rent payment using a credit card.</li>
                </ul>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Prohibited Activities:</strong>
            <ol className=" ml-6 mt-2">
                <li className="mb-2">Fraudulent activities, including but not limited to creating fake accounts or making false referrals, will result in disqualification from the Program.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Reward Redemption:</strong>
            <ol className="list-decimal ml-6 mt-2">
                <li className="mb-2"> Participants can redeem their earned rewards once they accumulate a minimum balance of 500 rupees in their Payinstacard account.</li>
                <li className="mb-2"> Rewards can be withdrawn to a linked bank account, as per Payinstacard's standard procedures.</li>
                <li className="mb-2">Payinstacard reserves the right to modify the minimum redemption amount and redemption options at its discretion.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Termination and Modification:</strong>
            <ol className=" ml-6 mt-2">
                <li className="mb-2">Payinstacard reserves the right to terminate, suspend, or modify the Program or these terms and conditions at any time, without prior notice.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Disputes:</strong>
            <ol className="ml-6 mt-2">
                <li className="mb-2">Any disputes related to the Program will be resolved at the sole discretion of Payinstacard.</li>
            </ol>
        </li>
        <li className="mb-2">
            <strong>Agreement:</strong>
            <ol className=" ml-6 mt-2">
                <li className="mb-2"> Participation in the Program constitutes acceptance of these terms and conditions.</li>
            </ol>
        </li>
    </ol>
</div>


      

      
      </div>
    </div>
  );
};

export default ReferralProgram;
