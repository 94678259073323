import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import axios from "axios";
import { APIUrls } from "../baseUrl/BaseUrl";
import _ from "lodash";

const initialState = {
  emailVerified: false,
  kyc_verified: false,
  paymentDisabled: true,
  userLoading: false,

  referal_url: "",
  referal_code: "",
  earning_total: "",
  withdrawal_arrays: [],
  earning_arrays: [],
  transaction_arrays: [],
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateEmailVerified(state, action) {
      state.emailVerified = action.payload;
    },
    updatekyc_verified(state, action) {
      state.kyc_verified = action.payload;
    },
    updatePaymentDisabled(state, action) {
      state.paymentDisabled = action.payload;
    },

    updateRefralURL(state, action) {
      state.referal_url = action.payload;
    },
    updateRefralCode(state, action) {
      state.referal_code = action.payload;
    },

    updateEarning(state, action) {
      state.earning_total = action.payload;
    },

    updateWithdrawalList(state, action) {
      state.withdrawal_arrays = action.payload;
    },
    updateEarningList(state, action) {
      state.earning_arrays = action.payload;
    },
    updateTransactionsList(state, action) {
      state.transaction_arrays = action.payload;
    },

    setLoading(state, action) {
      state.userLoading = action.payload;
    },
  },
});

const {
  setLoading,
  updateEmailVerified,
  updatekyc_verified,
  updatePaymentDisabled,
  updateRefralCode,
  updateRefralURL,
  updateEarning,
  updateWithdrawalList,
  updateEarningList,
  updateTransactionsList,
} = userSlice.actions;

// Create a thunk action creator
export const fetchUser = (tokens) => {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      };
      // Dispatch a loading action to indicate the start of the request
      dispatch(setLoading(true));

      // Make an asynchronous API request
      await axios
        .get(APIUrls.get_userData, config)
        .then((data) => {
          dispatch(setLoading(false));

          const datas = data.data;

          console.log(datas);

          if (data.status === 200) {
            const {
              kyc_status,
              paymentDisabled,
              emailVerified,
              total_earnings,
              withdrawals,
              EarningsArray,
              transactions,
            } = datas?.response?.UserData;

            console.log("USERDATA", datas.response.UserData);

            console.log("DATASs", datas.response);
            console.log("EMAIL:", emailVerified);
            console.log("KYC", kyc_status);
            console.log("PAYMEN", paymentDisabled);
            console.log("EARNING:", total_earnings);

            dispatch(updateEmailVerified(emailVerified));
            dispatch(updatekyc_verified(kyc_status));
            dispatch(updatePaymentDisabled(paymentDisabled));
            dispatch(updateEarning(total_earnings));
            dispatch(updateWithdrawalList(withdrawals));
            dispatch(updateEarningList(EarningsArray));
            dispatch(updateTransactionsList(transactions));
          }

          if (data.status !== 200) {
            console.log(datas);
          }

          console.log("DATA", data);
        })
        .catch((error) => {
          dispatch(setLoading(false));

          console.log(error);
        });

      // Dispatch a success action with the fetched data
      //dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setLoading(false));
      // Dispatch an error action if the request fails
      //dispatch(setError(error.message));
      console.log(error);
    }
  };
};

// Create a thunk action creator
export const generateReferal = (tokens) => {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      };
      // Dispatch a loading action to indicate the start of the request
      dispatch(setLoading(true));

      // Make an asynchronous API request
      await axios
        .get(APIUrls.generate_referals, config)
        .then((data) => {
          dispatch(setLoading(false));

          const datas = data.data;

          console.log(datas);

          if (data.status === 200) {
            const referal_data = datas.response;

            dispatch(updateRefralURL(referal_data?.referal_url));
            dispatch(updateRefralCode(referal_data?.referal_code));
          }

          if (data.status !== 200) {
            console.log(datas);
          }

          console.log("DATA2", data);
        })
        .catch((error) => {
          dispatch(setLoading(false));

          console.log(error);
        });

      // Dispatch a success action with the fetched data
      //dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setLoading(false));
      // Dispatch an error action if the request fails
      //dispatch(setError(error.message));
      console.log(error);
    }
  };
};
