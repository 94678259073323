import React from "react";

import { Icon } from "@iconify/react";

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import VECTOR from "./assets/Warning-pana 1.svg";

const Ipblocklayout = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="font-lato">
      <Helmet>
        <meta charSet="utf-8" />
        <title>IP BLOCK</title>
        <link rel="canonical" href="https://payinstacard.com/about-us" />
      </Helmet>

      <div className="w-full">
        <div className="flex justify-center ">
          <div className="max-w-sm ">
            <div className=" py-5">
              <div className="flex justify-center">
                <img src={VECTOR}></img>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-6 text-center">
          <h1 className="text-4xl font-bold text-[#565656]">
            Unauthorized Network
          </h1>

          <h1 className="text-2xl text-[#565656] mt-2">
            We suggest you to connect mobile network
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center ">
          <div className="max-w-6xl p-4 md:p-6 ">
            <div className="flex justify-center mb-6">
              <button
                onClick={() => window.location.reload()}
                className="px-16 py-2  bg-[#00006B] rounded-[22px] text-white rounded-md hover:bg-blue-600 focus:outline-none"
              >
                Reload
              </button>
            </div>

            <p className="mb-6 text-[#565656]">
              Your security is our top priority! To ensure a safe and seamless
              transaction experience, we recommend connecting via your mobile
              network instead of an unsecured Wi-Fi network.
            </p>
            <p className="mb-2 text-[#565656]">Why Choose Mobile Network ?</p>
            <ul className="list-disc pl-6 mb-6 text-[#565656]">
              <li>
                <span className="font-bold">Enhanced Security:</span> Mobile
                networks provide a more secure environment for your
                transactions, protecting your sensitive information.
              </li>
              <li>
                <span className="font-bold">Seamless Transactions:</span> Enjoy
                smooth and uninterrupted transactions without compromising on
                security.
              </li>
              <li>
                <span className="font-bold">Peace of Mind:</span> Connect with
                confidence, knowing that your financial data is safeguarded.
              </li>
            </ul>
            <p className="mb-6 text-[#565656]">
              When you connect to an unsecured network, our security screen will
              guide you back to a safer option. Remember to reload the page once
              connected to your mobile network to access the site securely.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ipblocklayout;
