import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState, Suspense } from "react";
// import { useAuth } from "../stores/AuthContext";
import Loader from "../components/loader/Loader";
import { Link } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";
import AboutUs from "../components/policies/AboutUs";
import PrivacyPolicy from "../components/policies/PrivacyPolicy";
import RefundPolicy from "../components/policies/RefundPolicy";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { APIUrls } from "../baseUrl/BaseUrl";
import { toast } from "react-toastify";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { payActions } from "../stores/IndexRedux";
import ComePage from "../components/cards/payment/ComePage";
import ReferralProgram from "../components/profiles/ReferralProgram";

const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const MainLayout = React.lazy(() => import("../layouts/MainLayout"));
const LoginWrapper = React.lazy(() =>
  import("../components/cards/login/LoginWrapper")
);
const Login = React.lazy(() => import("../components/cards/login/Login"));
const LoginOTPSubmit = React.lazy(() =>
  import("../components/cards/login/LoginOTPSubmit")
);
const CategorySelectionCard = React.lazy(() =>
  import("../components/cards/CategorySelectionCard")
);
const PaymentWrapper = React.lazy(() =>
  import("../components/cards/payment/PaymentWrapper")
);
const PaymentCard = React.lazy(() =>
  import("../components/cards/payment/PaymentCard")
);

const Contactus = React.lazy(() => import("../pages/Contactus"));
const PaymentSuccess = React.lazy(() =>
  import("../components/cards/PaymentSuccess")
);
const PaymentFailure = React.lazy(() =>
  import("../components/cards/PaymentFailure")
);
const MainProfilePage = React.lazy(() => import("../pages/MainProfilePage"));
const MyProfile = React.lazy(() => import("../components/profiles/MyProfile"));
const Mytransaction = React.lazy(() =>
  import("../components/profiles/Mytransaction")
);
const KYCVerification = React.lazy(() =>
  import("../components/profiles/KYCVerification")
);
const AllTransactions = React.lazy(() =>
  import("../components/profiles/AllTransactions")
);
const HouseRentTransactions = React.lazy(() =>
  import("../components/profiles/HouseRentTransactions")
);
const OfficeRentTransactions = React.lazy(() =>
  import("../components/profiles/OfficeRentTransactions")
);
const MaintenanceTransactions = React.lazy(() =>
  import("../components/profiles/MaintenanceTransactions")
);
const EducationFeeTransactions = React.lazy(() =>
  import("../components/profiles/EducationFeeTransactions")
);
const Beneficiary = React.lazy(() =>
  import("../components/profiles/Beneficiary")
);
const Error404 = React.lazy(() => import("../pages/Error404"));
const Terms = React.lazy(() => import("../components/policies/Terms"));
const PrepaidCars = React.lazy(() =>
  import("../components/policies/PrepaidCars")
);

const PaymentVErifyWrapper = React.lazy(() =>
  import("../components/cards/PaymentVerifyWrapper")
);
const PaymentCardUtilsimport = React.lazy(() =>
  import("./../components/cards/payment/PaymentCardUtils")
);
const ComingSoon = React.lazy(() => import("../pages/ComingSoon"));

const Referralmainpage = React.lazy(() => import("../pages/Referralmainpage"));

const Earnings = React.lazy(() => import("../components/profiles/Earnings"));

const PrivateRoute = ({ component: Component }) => {
  const [path, setPath] = useState();
  const [isLoading, setIsLoading] = useState();
  const { isProfileComplete, kycVerified, panVerified, aadhaarVerified } =
    useSelector((state) => state.pay);
  const location = useLocation();
  const { currentUser, getAccessToken } = useAuth();
  const { KYCRefetch } = useSelector((state) => state.pay);
  const [statusCode, setStatusCode] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, refetch, data, error, status } = useQuery({
    queryKey: ["kycDetailsGlobalKey"],
    queryFn: async () => {
      try {
        if (!currentUser) {
          return;
        }
        const token = await getAccessToken();
        const response = await fetch(APIUrls.fetchKYCDetails, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();
        console.log(responseJson);
        setStatusCode(responseJson?.code);
        if (_.isEmpty(responseJson)) {
          toast.error("Something went wrong !!");
          throw new Error("Something went wrong !!");
        } else if (responseJson?.code === 200) {
          if (responseJson.response.KYCDetails.aadhaarVerified) {
            dispatch(
              payActions.updateAadhaarVerification({ aadhaarVerified: true })
            );
          }

          if (responseJson.response.KYCDetails._PANVerified) {
            dispatch(payActions.updatePANVerification({ panVerified: true }));
          }

          if (
            responseJson.response.KYCDetails.aadhaarVerified &&
            responseJson.response.KYCDetails._PANVerified
          ) {
            dispatch(payActions.updateKYCVerification({ kycVerified: true }));
          }

          return responseJson.response;
        } else if (responseJson?.code === 400) {
          dispatch(
            payActions.updateAadhaarVerification({ aadhaarVerified: false })
          );

          dispatch(payActions.updatePANVerification({ panVerified: false }));

          dispatch(payActions.updateKYCVerification({ kycVerified: false }));
          return 400;
        } else if (responseJson?.code !== 200) {
          const message = responseJson.message;
          toast.error(message);
          throw new Error(message);
        }
      } catch (error) {
        console.log("error", error);
        toast.error("Something went wrong.");
        throw new Error(error);
      }
    },
  });

  console.log(status);

  useEffect(() => {
    dispatch(payActions.updateKYCRefetchGlobal({ globalRefetch: refetch }));
  }, [refetch]);

  useEffect(() => {
    {
      console.log("maillayout", currentUser);
    }
    // if CUStom data is not  available
    if (!_.isEmpty(currentUser)) {
      console.log("maillayout2", currentUser);
      console.log(
        "has reload",
        _.has(currentUser.reloadUserInfo, "customAttributes")
      );
      if (_.has(currentUser, "reloadUserInfo")) {
        if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
          dispatch(payActions.updateProfileStatus({ profileStatus: true }));
        } else {
          dispatch(payActions.updateProfileStatus({ profileStatus: false }));
        }
      } else {
        navigate("/");
      }
    }
  }, [currentUser]);

  console.log("comom profile", kycVerified);
  useEffect(() => {
    setPath(_.includes(location.pathname.split("/"), "profile"));
  }, [location]);
  console.log("path", path);

  return (
    <>
      {currentUser ? (
        status === "loading" ? (
          <Loader />
        ) : status === "error" ? (
          data === 400 ? (
            path ? (
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            ) : (
              <Navigate to="/profile/kyc-verification" />
            )
          ) : (
            <Navigate to="" />
          )
        ) : (isProfileComplete && kycVerified) || path ? (
          <Suspense fallback={<Loader />}>
            <Component />
          </Suspense>
        ) : (
          <Navigate to="/profile/kyc-verification" />
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <MainLayout />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loader />}>
            <LandingPage />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<Loader />}>
                <CategorySelectionCard />
              </Suspense>
            ),
          },

          {
            path: "login",
            element: (
              <Suspense fallback={<Loader />}>
                <LoginWrapper />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                ),
              },
              {
                path: "submit-otp",
                element: (
                  <Suspense fallback={<Loader />}>
                    <LoginOTPSubmit />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "payout",
            element: <PrivateRoute component={PaymentWrapper} />,
            children: [
              {
                path: ":rent",
                element: <PrivateRoute component={PaymentCard} />,
              },
              {
                path: "utility/:utils",
                element: (
                  <Suspense fallback={<Loader />}>
                    <PaymentCardUtilsimport />
                  </Suspense>
                ),
              },
              {
                path: "comingsoon",
                element: (
                  <Suspense fallback={<Loader />}>
                    <ComingSoon />
                  </Suspense>
                ),
              },
              {
                path: "comingsoons",
                element: (
                  <Suspense fallback={<Loader />}>
                    <ComePage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "paymentverify/:orderkeyid",
            element: <PrivateRoute component={PaymentVErifyWrapper} />,
          },

          {
            path: "success",
            element: <PrivateRoute component={PaymentSuccess} />,
          },
          {
            path: "failed",
            element: <PrivateRoute component={PaymentFailure} />,
          },
        ],
      },
      {
        path: "profile",
        element: <PrivateRoute component={MainProfilePage} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={MyProfile} />,
          },
          {
            path: "my-transactions",
            element: <PrivateRoute component={Mytransaction} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={AllTransactions} />,
              },
              {
                path: "house-rent",
                element: <PrivateRoute component={HouseRentTransactions} />,
              },
              {
                path: "office-rent",
                element: <PrivateRoute component={OfficeRentTransactions} />,
              },
              {
                path: "maintenance",
                element: <PrivateRoute component={MaintenanceTransactions} />,
              },
              {
                path: "education-fee",
                element: <PrivateRoute component={EducationFeeTransactions} />,
              },
            ],
          },
          {
            path: "kyc-verification",
            element: <PrivateRoute component={KYCVerification} />,
          },
          {
            path: "beneficiary",
            element: <PrivateRoute component={Beneficiary} />,
          },
        ],
      },
      {
        path: "referral",
        element: <PrivateRoute component={Referralmainpage} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={ReferralProgram} />,
          },
          {
            path: "earnings",
            element: <PrivateRoute component={Earnings} />,
          },
        ],
      },

      {
        path: "terms-and-conditions",
        element: (
          <Suspense fallback={<Loader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: "about-us",
        element: (
          <Suspense fallback={<Loader />}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "refund-policy",
        element: (
          <Suspense fallback={<Loader />}>
            <RefundPolicy />
          </Suspense>
        ),
      },
      {
        path: "prepaid-card/terms-and-conditions",
        element: (
          <Suspense fallback={<Loader />}>
            <PrepaidCars />
          </Suspense>
        ),
      },
      {
        path: "contact-us",
        element: (
          <Suspense fallback={<Loader />}>
            <Contactus />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);
