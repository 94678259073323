import React, { useState } from "react";
import Modal from "./Modal";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { toast, ToastContainer } from "react-toastify";
import aadhaarAvatar from "../../assets/aadhaarAvatar.svg";
import { Radio } from "@material-tailwind/react";
import _ from "lodash";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { payActions } from "../../stores/IndexRedux";

const AddBeneficiaryModal = ({ isOpenModal, setIsOpenModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [isThirdOpen, setIsThirdOpen] = useState(false);
  const { getAccessToken, currentUser } = useAuth();
  const [paymentType, setPaymentType] = useState("Bank");
  const dispatch = useDispatch();
  const {beneficiaryRefetch} = useSelector((state)=>state.pay)
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm();
  const addBenificer = async (data, event) => {
    console.log("hello");

    setIsLoading(true);
    event.preventDefault();




    let {
      FirstName,
      bankAccount,
      beneficiary_address,
      beneficiary_email,
      beneficiary_phone,
      ifsc_code,
    } = data;
    let upi;
    console.log(data);
    if (paymentType === "UPI") {
      upi = bankAccount;
      bankAccount = "";
    }
    const benData = {
      FirstName,
      beneficiary_email,
      beneficiary_address,
      beneficiary_phone,
      payment_info: {
        type: paymentType,
        bankAccount,
        ifsc_code:ifsc_code?.toUpperCase(),
        upi_code: upi,
      },
    };

    try {
      const token = await getAccessToken();
      const response = await fetch(APIUrls.add_benificer, {
        method: "POST",
        body: JSON.stringify(benData),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      const responseJson = await response.json();
      if (_.isEmpty(responseJson)) {
        return toast.error("Something went wrong !!");
      }

      if (responseJson?.code === 201) {
        const message = responseJson.response.message;

        toast.success("Profile data added successfully !");
       beneficiaryRefetch()
        setIsOpenModal(false);
        return;
      }

      if (responseJson?.code === 200) {
        const message = responseJson.response.message;
        toast.success("Profile has been updated Successfully !");
        beneficiaryRefetch();
        setIsOpenModal(false);
        return;
      }
      if (responseJson?.code === 409) {
        const message = responseJson.response.message;
        toast.error(message);
        return;
      }
      if (responseJson?.code === 429) {
        const message = responseJson.response.message;
        return toast.warning("Beneficiary with this email exists already");
      }

      if (responseJson?.code === 439) {
        const message = responseJson.response.message;
        return toast.warning("Beneficiary of Limit 5 is reached !");
      }


      if (responseJson?.code === 400) {
        const message = responseJson.response.message;
        return toast.error(message);
      }

      console.log(responseJson);
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      toast.error("Something went Wrong !");
    }
  };


  console.log(isFirstOpen, isSecondOpen, isThirdOpen);

  const handleGoBack = () => {
    if (isThirdOpen) {
      setIsThirdOpen(false);
      setIsSecondOpen(true);
    } else if (isSecondOpen) {
      setIsSecondOpen(false);
      setIsThirdOpen(false);
      setIsFirstOpen(true);
    }
  };

  const getOTPHandler = (data, event) => {
    setIsFirstOpen(false);
    setIsThirdOpen(false);
    setIsSecondOpen(true);
  };

  const submitOTP = (data, event) => {
    setIsFirstOpen(false);
    setIsSecondOpen(false);
    setIsThirdOpen(true);
  };
  return (
    
    <Modal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}>
      <div className="min-w-[20rem] xs:min-w-[25rem] sm:min-w-[35rem] min-h-[20rem] py-[2rem] px-[2rem] flex flex-col gap-[2rem] relative max-h-[85vh] overflow-y-auto">
        <Icon
          icon="radix-icons:cross-circled"
          className="absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={() => {
            setIsOpenModal(false);
          }}
        />

        <div className="pb-[1rem] border-b border-black/[0.2] flex flex-row gap-[1rem] items-center">
          {isSecondOpen || isThirdOpen ? (
            <Icon
              icon="octicon:arrow-left-16"
              className="text-[1.5rem] cursor-pointer"
              onClick={() => {
                handleGoBack();
              }}
            />
          ) : null}

          <p className="text-[#2F2F2F] font-bold text-[1.2rem] flex">
            Add New Benificiary
          </p>
        </div>
        <div className="flex flex-row justify-center w-full">
          <form
            action=""
            onSubmit={handleSubmit(addBenificer)}
            className="flex flex-col gap-[2rem] w-full "
          >
            <div className=" flex flex-col gap-[1rem]">
              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="FirstName"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Full Name
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("FirstName", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                  })}
                />

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {errors?.FirstName && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.FirstName?.message}</span>
                  </div>
                )}
              </div>
              {/* <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="LastName"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("LastName", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                  })}
                /> */}

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {/* {errors?.LastName && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.LastName?.message}</span>
                  </div>
                )}
              </div> */}

              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="beneficiary_email"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Beneficiary Email
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("beneficiary_email", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                  })}
                />

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {errors?.beneficiary_email && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.beneficiary_email?.message}</span>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="beneficiary_address"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Address
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("beneficiary_address", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                  })}
                />

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {errors?.beneficiary_address && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.beneficiary_address?.message}</span>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="beneficiary_phone"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Phone
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("beneficiary_phone", {
                    required: "This field is required.",
                    minLength: {
                      value: 10,
                      message: "This minimum length should be 3.",
                    },
                    maxLength: {
                      value: 14,
                      message:
                        "The maxium length should be 10 along with country code.",
                    },
                  })}
                />

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {errors?.beneficiary_phone && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.beneficiary_phone?.message}</span>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="amount"
                  className="text-[#8A8A8A] text-[0.8rem] lg:text-[1rem] font-bold "
                >
                  Select anyone
                </label>
                <div className="flex gap-10 text-[#8A8A8A] active:text-black text-[0.9rem] lg:text-[1.12rem] font-bold">
                  <Radio
                    id="bankAccount"
                    name="type"
                    color="gray"
                    label="Bank Account"
                    className=""
                    defaultChecked
                    onChange={(e) => {
                      if (e.target.value) {
                        setPaymentType("Bank");
                        console.log("Bank");
                      }
                    }}
                  />
                  <Radio
                    id="upi"
                    name="type"
                    color="gray"
                    label="UPI"
                    disabled={true}
                    onChange={(e) => {
                      if (e.target.value) {
                        setPaymentType("UPI");
                        console.log("UPI");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <label
                  htmlFor="bankAccount"
                  className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                >
                  Beneficiary’s account number/ UPI ID
                </label>
                <input
                  type="text"
                  className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                  {...register("bankAccount", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                  })}
                />

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {errors?.bankAccount && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{errors?.bankAccount?.message}</span>
                  </div>
                )}
              </div>
              {paymentType === "Bank" ? (
                <div className="flex flex-col gap-[0.5rem]">
                  <label
                    htmlFor="ifsc_code"
                    className="text-[#8A8A8A] text-[0.8rem] xs:text-[1rem] font-bold "
                  >
                    IFSC code
                  </label>
                  <input
                    type="text"
                    className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] xs:text-[1rem] "
                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                    {...register("ifsc_code", {
                      required: "This field is required.",
                      minLength: {
                        value: 3,
                        message: "This minimum length should be 3.",
                      },
                    })}
                  />

                  {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                  {errors?.ifsc_code && (
                    <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                      <Icon
                        icon="material-symbols:info-rounded"
                        className="text-[1.5rem]"
                      />
                      <span>{errors?.ifsc_code?.message}</span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isLoading}
                className={`${
                  !isValid || isLoading ? "bg-[#00006B]/[0.5]" : "bg-[#00006B]"
                } text-center  rounded-[30px] text-white py-[0.5rem] font-[800] flex flex-row items-center w-[15rem] justify-center gap-[0.5rem]`}
              >
                {isLoading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                Save
              </button>
            </div>
          </form>
        </div>
   
      </div>
    </Modal>
  );
};

export default AddBeneficiaryModal;
