let baseUrl = "http://localhost:4000";
let cashfreeMode = "sandbox";
let ReturnUrl = "http://localhost:3000";
const type = "PROD";

if (type === "PROD") {
  baseUrl = "https://apiv2.payinstacard.com";
  ReturnUrl = "https://payinstacard.com";
  cashfreeMode = "production";
} else if (type === "STAGING") {
  baseUrl = "https://stagingapiv2.payinstacard.com";
  ReturnUrl = "https://staging.payinstacard.com";
  cashfreeMode = "production";
} else {
  baseUrl = "http://localhost:4000";
}

export const APIUrls = {
  updateProfile: `${baseUrl}/users/profile-update`,
  generateAadhaarOTP: `${baseUrl}/verification-suits/generateAadhaarOTP`,
  submitAadhaarOTP: `${baseUrl}/verification-suits/submitAadhaarOTP`,
  verifyPAN: `${baseUrl}/verification-suits/verifyPAN`,
  add_benificer: `${baseUrl}/users/add-new-ben`,
  fetchBen: `${baseUrl}/users/fetch-beneficiary`,
  make_payment: `${baseUrl}/payment/create_order_post`,
  fetch_order: `${baseUrl}/payment/order_detail`,
  deleteBenficiery: `${baseUrl}/users/remove-ben-new`,
  fetchKYCDetails: `${baseUrl}/users/fetch-KYCDetails`,
  getTransactions: `${baseUrl}/users/get-transactions`,
  airpay_payment: `${baseUrl}/payment/airpay_post`,
  airpay_verify: `${baseUrl}/payment/airpay/verify-order`,
  login_logs: `${baseUrl}/users/update-login-log`,
  get_persentage: `${baseUrl}/users/get-commission`,
  set_feedback: `${baseUrl}/users/user-feedback`,
  email_verify: `${baseUrl}/users/verify-email`,
  submit_otp_email: `${baseUrl}/users/email-otp-submit`,
  login_blocker: `${baseUrl}/users/login-prevention`,
  log_visitors: `${baseUrl}/users/visitor/logs`,
  get_wallet_limit: `${baseUrl}/api/v2/dashboarding/get-wallet-limit`,
  get_userData: `${baseUrl}/users/get/data`,
  validate_referals: `${baseUrl}/referal/validatereferal`,
  generate_referals: `${baseUrl}/referal/getreferaid`,
  contactus_post: `${baseUrl}/users/contactus`,
  withdrawal_request: `${baseUrl}/referal/request-widthdrawal`,
  get_settingsData: `${baseUrl}/users/get-settings`,
  razorPayroll: `${baseUrl}/paymentg/razorpay/create-order`,
  paymentStatus2: `${baseUrl}/paymentg/razorpay/status`,
  cashFreeCreateOrder: `${baseUrl}/paymentg/cashfree/create-order`,
  cashFreePaymentStatus: `${baseUrl}/paymentg/cashfree/status`,
  cashFreePaymentResponse: `${baseUrl}/paymentg/cashfree/payment_response`,
  ReturnUrl: ReturnUrl,
  cashfreeMode: cashfreeMode,

  // const fetchUserData = async () => {
  //     try {
  //       const response = await fetch('/api/user-details'); // Replace with your API endpoint
  //       const userData = await response.json();
  //       setUser(userData);
  //     } catch (error) {
  //       console.error('Error fetching user data:', error);
  //     }
  //   };
};
