import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './stores/AuthContext';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import store from './stores/IndexRedux';
import {Provider} from 'react-redux';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-56QGQPCW'
}
 
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <AuthContextProvider >
   
  <React.StrictMode>
    <App />
  </React.StrictMode>
  
  </AuthContextProvider>
  </Provider> 
);

reportWebVitals();
