import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";


function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className="font-lato">
     <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy</title>
                <link rel="canonical" href="https://payinstacard.com/privacy-policy" />
            </Helmet>
      <div
        class="p-14"
        style={{
          background: "linear-gradient(91.38deg, #F46E5C 0%, #F80D6E 100%)",
        }}
      >
        <div className="text-center text-white flex flex-col gap-[1rem] ">
          <h1 className="lg:text-4xl md:text-3xl xl:text-[3rem] text-2xl font-bold  ">Privacy Policy</h1>

          <p className="lg:text-4sm md:text-3sm xl:text-[1rem] sm:text-2sm ">Last Updated on 14th June 2023</p>
        </div>
      </div>

      <div className="  px-4 py-8 max-w-[1320px] w-full mx-auto leading-10 flex flex-col gap-[1rem]">

        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black ">
        This privacy policy sets out how to use and protect any information that you provide to PAYINSTACARD PVT LTD when you use this website. <br /> PAYINSTACARD PVT LTD is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. <br /> PAYINSTACARD PVT LTD may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is revised and effective from 15/10/2022.
        </p>

        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold"> What we collect: We may collect the following information</h2>

        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black ">
        Name and Job title Contact Information including email address & phone number Demographic information such as city, postcode, preferences and interests Other information relevant to service enquiries, customer surveys and/or offers
        </p>
        <p className= "text-[0.9rem] sm:text-[1.1rem] font-bold text-black ">
        What we do with the information we gather We require this information to understand your needs and provide you with the best service, and in particular, for the following reasons:
        </p>
        <p className= "text-[0.9rem] sm:text-[1.1rem]  text-black ">
        Internal record keeping. We may use the information to improve our products and services. We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided. From time to time, we may also use your information to contact you for feedback, and market research purposes. We may contact you by email, phone, fax, or mail. We may use the information to customize the website according to your interests.
        </p>

        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold">Security</h2>

        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black">
        We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
        </p>
        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold">How we use cookies</h2>
        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black">
        A cookie is a small file that asks permission to be placed on your computer’s hard drive. Once you agree, the file is added, and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes, and then the data is removed from the system. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
        </p>
        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold"> Links to other websites</h2>
        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black">
        Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites, and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
        </p>
        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold">  Controlling Your Personal Information</h2>
        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black">
        You may choose to restrict the collection or use of your personal information in the following ways: whenever you are asked to fill in a form on a website, look for the box that you can click to indicate that you do not want the information to be used by anybody for promotional purposes. If such a box is not available, you may choose not to fill out such a form. However, by submitting the filled-in enquiry form, you will be construed to have foregone your right and the company may choose to send promotional emails and materials from time to time. if you have previously agreed to us using your personal information for promotional purposes, you may change your mind at any time by writing to or emailing us at info@PAYINSTACARD PVT LTD.com We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting. 
        </p>

        <h2 className="text-[1.2rem] sm:text-[1.5rem] font-bold">Contacting Us</h2>
        <p className= "text-[0.9rem] sm:text-[1.1rem] text-black">
        If there are any questions regarding this privacy policy, you may contact us using the information below: support@payinstacard.com <br /> Tel.: 1800 571 6878 (Toll-free)
        </p>
       
      </div>
    </div>
  );
}

export default PrivacyPolicy;
